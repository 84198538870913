<template>
  <div style="width: 100%;">
    <v-app>
      <v-snackbar
        :timeout="3000"
        :value="true"
        absolute
        top
        color="success"
        right
        text
        v-if="appear"
      >
        New department created
      </v-snackbar>
      <NewDepartment :dialogModal="showup" @offModal="cancel($event)" @getResponse="responseData($event)"></NewDepartment>
      <v-dialog
        v-model="dialogModal"
        max-width="500px"
        :persistent="true"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Change Faculty</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    @change="onTest($event)"
                    v-model="selected"
                    label="Choose faculty"
                    name="Faculty"
                    v-validate="'required'"
                    :items="faculty"
                    return-object
                  ></v-select>
                  <span style="color: red;"
                    v-show="errors.has('Faculty')">{{ errors.first('Faculty') }}</span>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="edit('faculty_id')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table
        :headers="headers"
        :items="departments"
        :loading="loading"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:item.id="{item}">
          <v-chip small :color="`#9c9c9c`" style="color: white; font-weight: 600; width: 25px; padding: 8px;">
            {{departments.map(function(x) {return x.id; }).indexOf(item.id)+1}}
          </v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon :color="'#0a43ae'" @click="deleteDepartment(item.id,item.title)">delete</v-icon>
        </template>
        <template v-slot:item.title="{ item }">
          <input type="text" style="width: 400px;" :value="item.title" :id="'title'+item.id" @dblclick="editMode(item.id,'title')" @blur="blurMode(item.id,'title')" @keyup.enter="blurMode(item.id,'title')" readonly/>
        </template>
        <template v-slot:item.faculty="{ item }">
          <input type="text" style="width: 100%;" :value="item.faculty" :id="'faculty'+item.id" @dblclick="onModal(item,'information technology','1')" readonly/>
        </template>
        <template v-slot:item.code="{ item }">
          <input type="text" style="width: auto;" :value="item.code" :id="'code'+item.id" @dblclick="editMode(item.id,'code')" @blur="blurMode(item.id,'code')" @keyup.enter="blurMode(item.id,'code')" readonly/>
        </template>
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Departments</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="callModal"
              style="text-transform: none;"
            >
              New Department
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mt-2"
            @click="edit(item),state='edit'"
          >
          </v-icon>
        </template>
      </v-data-table>
    </v-app>
    <vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>
<script>
import {
  mdiAccount,
  mdiPencil,
  mdiShareVariant,
  mdiDelete,
} from '@mdi/js'
import NewDepartment from './NewDepartment'
import Swal from "sweetalert2"
export default {
  components: {
    NewDepartment,
  },
  data: () => ({
    icons: {
      mdiAccount,
      mdiPencil,
      mdiShareVariant,
      mdiDelete,
    },
    headers: [
      { text: '#', value: 'id' },
      { text: 'Title', value: 'title' },
      { text: 'Faculty', value: 'faculty' },
      { text: 'Code', value: 'code' },
      { text: '', value: 'action' },
    ],
    date: new Date().toISOString().substr(0, 7),
    modal: false,
    logger: [],
    header: '',
    facults: [],
    state: '',
    id: '',
    faculty: [],
    departments: [],
    showup: false,
    dialogModal: false,
    selected: '',
    myId: '',
    loading: false,
    appear: false,
  }),
  created () {
    this.fetchDepartments()
  },
  methods: {
    editMode: function (id, key) {
      var dept = document.getElementById(key + '' + id)
      dept.focus()
      dept.readOnly = false
      dept.classList.add('editMode')
    },
    blurMode (id, key) {
      var dept = document.getElementById(key + '' + id)
      dept.readOnly = true
      dept.classList.remove('editMode')
      this.editDepartment(id, key, dept.value)
    },
    async editDepartment (id, key, value) {
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        id: id,
        key: key,
        value: value,
        deptKey: '',
      }
      try {
        await this.axios({
          url: 'manipulate_department/0',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else if (e.response && e.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(e)
        }
      }
    },
    async edit (key) {
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        id: this.myId,
        key: key,
        value: this.selected.value,
      }
      try {
        const response = await this.axios({
          url: 'manipulate_department/0',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (response.status === 200) {
          this.dialogModal = false
          this.fetchDepartments()
          this.selected = ''
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else if (e.response && e.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(e)
        }
      }
    },
    close () {
      this.dialogModal = false
    },
    async fetchDepartments () {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_departments',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.departments = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } if (error.response && error.response.status === 400) {
          this.loading = false
        } else {
          console.log(error)
        }
      }
    },
    async fetchSelectedFacults () {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_faculty',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.faculty = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } if (error.response && error.response.status === 400) {
          this.loading = false
        } else {
          console.log(error)
        }
      }
    },
    deleteDepartment (id, title) {
      this.$confirm(
        {
          message: 'Delete ' + ' ' + title + ' ' + 'from list ?',
          button: {
            no: 'No',
            yes: 'Yes',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: async confirm => {
            if (confirm) {
              // ... do something
              if (localStorage.getItem('logged_user')) {
                this.logger = JSON.parse(localStorage.getItem('logged_user'))
              }
              this.header = this.logger.accessToken
              try {
                const res = await this.axios({
                  url: 'delete_department',
                  method: 'post',
                  data: {
                    id: id,
                  },
                  headers: {
                    Authorization: 'Bearer ' + this.header,
                  },
                })
                if (res.status === 200) {
                  this.departments = this.departments.filter(dept => dept.id !== id)
                  this.loading = false
                  this.fetchDepartments()
                  console.log(res.data)
                }
              } catch (error) {
                if (error.response && error.response.status === 401) {
                  this.$router.push({ name: 'login-page' })
                }
                if (error.response && error.response.status === 400) {
                  Swal.fire({
                    title: 'Error!',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                  })
                  this.loading = false
                } else {
                  console.log(error)
                }
              }
            }
          },
        },
      )
    },
    cancel (event) {
      this.showup = event
    },
    callModal () {
      this.showup = true
    },
    responseData (event) {
      this.appear = true
      this.departments.push(event)
    },
    onTest (event) {
      console.log(event)
    },
    onModal (item) {
      this.fetchSelectedFacults()
      this.selected = { text: item.faculty, value: item.facultyValue }
      this.dialogModal = true
      this.myId = item.id
      alert(this.myId)
    },
  },
}
</script>
<style scoped>
  .editMode {
    padding: 3px;
    background-color: #fdf9ff;
    font-size: 14px;
    border: 1px solid;
    border-radius: 3px;
  }
</style>
